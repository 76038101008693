.modal {
  .modal-dialog {
    .modal-content {
      .modal-header {
        .btn-close {
          position: absolute;
          right: 10px;
          top: 10px;
          @media (min-width: 576px) {
            right: 16px;
            top: 20px;
          }
          z-index: 1;

          &:focus {
            box-shadow: none;
          }
        }
      }

      border: none;
      border-radius: 10px;
    }
  }
}

.modal-open {
  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

#storageSize {
  max-width: 800px;
  @include primary-scroll;

  .modal-header {
    border: none;
    padding: 30px 0;

    .btn-close {
      position: absolute;
      right: 15px;
      top: 12px;

      &:focus {
        box-shadow: none;
      }
    }

    .section-heading {
      font-size: 24px;
    }
  }

  .modal-body {
    padding: 15px 0;
  }

  .measurement-scale {
    box-shadow: 0 2px 6px 1px $light-red-color;

    img {
      width: 100%;
      height: 60px;
    }
  }

  .scale-options {
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 12px;
  }

  .square-info {
    display: flex;
    justify-content: space-between;
    width: 665px;
    list-style: none;
    margin: auto;
    padding: 0;

    li {
      width: 53px;
      text-align: center;

      p {
        @include font(hind, bold);
        color: $road-grey;
      }
    }
  }

  .wizard {
    position: relative;

    .nav-tabs {
      position: relative;
      border: none;

      &::after {
        content: "";
        width: 80%;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        top: 38%;
        z-index: -1;
      }

      .nav-item {
        position: relative;

        .nav-link {
          width: 70px;
          height: 49px;
          margin-bottom: 0;
          background: transparent;
          color: $black;
          z-index: 10;
          border: 0;

          &.active {
            color: $se-primary-red;

            &::after {
              content: " ";
              position: absolute;
              left: 50%;
              transform: translate(-50%);
              opacity: 1;
              margin: 0 auto;
              bottom: 0;
              border: 10px solid transparent;
              border-bottom-color: $se-primary-red;
            }
          }

          &::after {
            content: " ";
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            opacity: 0;
            margin: 0 auto;
            bottom: 0;
            border: 5px solid transparent;
            border-bottom-color: $se-primary-red;
            transition: 0.1s ease-in-out;
          }
        }
      }
    }
  }

  .video-frame {
    max-width: 100%;
    max-height: 100%;
  }

  .size-description {
    display: flex;
    width: 100%;
    list-style: none;
    margin: 10px 0;
    padding: 0;
    justify-content: space-between;

    li {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      width: 120px;
      text-align: center;

      .similar {
        @include font(hind, bold);
        font-size: 13px;
        color: $road-grey;
        height: 38px;
      }

      .description {
        min-height: 50px;
        @include font(hind, regular);
        font-size: 13px;
        color: $road-grey;
      }
    }
  }

  .mobile-view-details {
    .nav-pills {
      .nav-item {
        margin-right: 0;

        .nav-link {
          width: 100%;
        }
      }
    }
  }
}

.sizeGuide-modal {
  width: 100%;
  border-radius: 0;
  @include primary-scroll;
  margin: 16px;
}

#myAccountModal {
  .modal-dialog {
    width: 600px;
  }

  .modal-content {
    border: none;

    .modal-header {
      background-color: $se-primary-red;
      border-radius: 10px 10px 0 0;
      padding: 12px;
      align-items: baseline;

      .modal-title {
        @include font(hind, regular);
        font-size: 18px;
        color: $white;
        line-height: 24px;
      }

      .btn-close {
        background: none;
        color: white;
        opacity: 1;
        font-size: 20px;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .modal-body {
      padding: 12px;

      p {
        color: $road-grey;
      }

      .fieldSet {
        display: flex;
        align-items: center;
        margin-top: 10px;

        .form-label {
          @include font(hind, bold);
          color: $road-grey;
          width: 100px;
          margin-bottom: 0;
        }

        select {
          border-radius: 0;
          box-shadow: none;
          border-color: #bfbfbf;
        }
      }
    }
  }
}

.sv-modal-popups {
  .react-datepicker__portal {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .react-datepicker__header {
    background-color: #f0f0f0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 10px 10px 0 10px;
    text-align: center;
    font-weight: bold;
  }

  .custom-datepicker {
    .react-datepicker__current-month {
      font-size: 1.2rem;
    }
    .react-datepicker__navigation {
      top: 0.75rem;
      height: 2rem;
      width: 2rem;
    }
    .react-datepicker__day-name {
      color: #999;
      font-size: 12px;
      text-transform: uppercase;
    }

    .react-datepicker__day {
      padding: 0px;
      margin: 3px;
      font-size: 14px;
      cursor: pointer;
    }
    .react-datepicker__day--today {
      font-weight: bold;
    }

    .react-datepicker__triangle {
      display: none;
    }
  }
  .modal-dialog {
    max-width: 100%;
    overflow-y: auto;
    width: auto;
    margin-right: 0.5rem;
    margin-left: 0.5rem;

    .modal-content {
      padding: 0.5rem 0.75rem;
      text-align: center;
      max-height: 100vh;
      overflow: auto;
      .modal-header {
        border-bottom: none;

        .modal-title {
          @include font(hind, bold);
          color: $dark-grey-color;
          font-size: 20px;
          line-height: 1.1;
        }
      }

      .modal-body {
        text-align: center;
        padding: 0;

        input.error {
          background: url(../../../public/images/icons/alert.svg) no-repeat scroll right 12px center;
          color: $se-primary-red;
        }
        input.movein-date {
          background: url(../../../public/images/icons/calender.svg) no-repeat scroll right 10px center;
        }
        input[type="date"]::-webkit-calendar-picker-indicator {
          background: transparent;
          bottom: 0;
          color: transparent;
          cursor: pointer;
          height: auto;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          width: auto;
        }
        .form-control {
          padding: 0.45rem 0.375rem 0.3rem;
        }
      }

      .modal-footer {
        border-top: none;
        justify-content: center;
        padding: 0;
        line-height: 1.2;
        .recaptcha-note,
        .recaptcha-note a {
          line-height: 1.2;
          font-size: 10px;
        }

        .se-primary-btn {
          margin-top: 0;
        }
      }
      .small-text {
        font-size: 14px;
      }
    }
  }
  @media (min-width: 336px) {
    .modal-dialog {
      max-width: 320px;
      margin-right: auto;
      margin-left: auto;
    }
  }
  @media (min-width: 768px) {
    .modal-dialog {
      max-width: 450px;
      width: 450px;
      .modal-content {
        padding: 1rem 1.25rem;
        .modal-header .modal-title {
          font-size: 30px;
        }
        .small-text {
          font-size: 16px;
        }
        .modal-footer {
          .recaptcha-note,
          .recaptcha-note a {
            font-size: 12px;
          }
        }
      }
    }
  }
}

#unitVideoModal {
  &.modal-dialog {
    width: fit-content;
    top: 40px;
    max-width: 100vw;
    margin: auto;

    .modal-content {
      box-shadow: 0 5px 15px $black;
      border-radius: 0;

      .modal-header {
        border-bottom: 0;
        position: relative;
        z-index: 2;
        padding: 0;
        width: 100%;

        .btn-close {
          background: url("../../../public/images/icons/cross.svg");
          background-position: center;
          background-repeat: no-repeat;
          color: $white;
          opacity: 1;
          font-size: 20px;
          top: -24px;
          right: -24px;
        }
      }
    }
  }

  .modal-content {
    .modal-body {
      .styles_video__xGKVK {
        .thumbnail {
          object-fit: cover;
        }

        .play-btn {
          position: absolute;
          z-index: 1;
          top: 45%;
          left: 44%;
          cursor: pointer;
        }
      }
    }
  }
}

.MuiPaper-root {
  border-radius: 0 !important;
}

#SvFilterModal {
  z-index: 9999;
  overflow: unset;
  backdrop-filter: contrast(0.5);

  .modal-content {
    .modal-header {
      border: none;
    }

    .modal-body {
      padding: 12px;
      max-height: 500px;
      overflow-y: auto;
      @include primary-scroll;

      .sv-grey-medium-text {
        @include font(hind, bold);
        margin-bottom: 10px;
      }

      .form-check {
        margin-bottom: 15px;

        .form-check-input {
          width: 19px;
          height: 19px;
          border: 1px solid rgba(102, 102, 102, 0.3);
          border-radius: 4px;

          &:checked {
            background-color: $se-primary-blue;
          }
        }

        .form-check-label {
          @include font(hind, regular);
          font-size: 16px;
          line-height: 30px;
          color: $road-grey;
        }
      }
    }

    .modal-footer {
      padding: 10px 20px;
      display: flex;
      justify-content: space-between;
      border-top: 1px solid $dark-grey;

      .learn-more {
        text-transform: initial;
        padding: 0;
        text-decoration: none;
      }
    }
  }

  .sv-size-help-guide {
    border: none;
    padding: 20px;
  }
}

.sisterbrand-modal {
  &.modal {
    .modal-dialog {
      .modal-content {
        border-radius: 12px;

        .modal-header {
          justify-content: center;
          padding-right: 32px;

          .modal-title {
            @include font(hind, bold);
            font-size: 18px;
            line-height: 24px;
            color: $road-grey;
            margin-bottom: 0;
          }

          .btn-close {
            position: absolute;
            right: 10px;
            top: 10px;
          }
        }

        .modal-body {
          text-align: center;
        }

        .modal-footer {
          justify-content: center;
          border: none;

          .primary-card-btn {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }
  }
}

#myAccountModal {
  max-width: 600px;
}

#thankyouModal {
  &.modal-dialog {
    .modal-content {
      .modal-header {
        padding: 15px;
        margin: auto;
        border: none;

        .section-heading {
          padding-top: 32px;
          margin-bottom: 26px;
        }
      }
    }
  }
}

#extraImagesModal {
  .modal-dialog {
    @include primary-scroll;
    // padding: 24px;
    // max-width: 100%;
    margin: 55px auto 0;

    .modal-header {
      border: none;
      padding: 0;

      .btn-close {
        top: -48px;
        right: 0;
      }
    }

    .modal-content {
      border: none;
      border-radius: 12px;
      padding: 24px;

      .modal-body {
        padding: 0;

        ul {
          margin-bottom: 0;
        }

        .img-scroll {
          @include image-scroll;
          height: 400px;
          overflow-y: auto;
        }

        .big-image {
          width: 100%;
          height: 500px;
          border-radius: 12px;
        }

        .small-images {
          width: 100%;
          height: 173px;
          margin-bottom: 8px;
          border-radius: 12px;
          object-fit: cover;
          cursor: pointer;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

#extraImagesModalMobile {
  .modal-dialog {
    .modal-content {
      border-radius: 0 !important;

      .modal-header,
      .modal-footer {
        padding: 12px;

        .btn-close {
          top: unset;
          right: 19px;
        }
      }

      .modal-body {
        @include primary-scroll;

        img {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

#featuresModal {
  .modal-dialog {
    max-width: 490px;
    padding: 0;
    border-radius: 8px;
    background-color: transparent;
    margin: 55px auto;

    .modal-content {
      background-color: transparent;
      // padding: 48px 72px;

      .modal-header {
        border: none;
        padding: 0;

        .btn-close {
          top: -40px;
          right: 0;
        }
      }

      .modal-body {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .sv-dark-black-bold-para {
          // padding-top: 24px;
          padding-bottom: 16px;
        }

        .feature-icon {
          width: 24px;
          height: 24px;
        }

        .sv-feature-title {
          @include font(hind, semi-bold);
          font-size: 14px;
          line-height: 16px;
          color: $gray-700;
        }

        .sv-feature-description {
          @include font(hind, regular);
          font-size: 14px;
          line-height: 16px;
          color: $gray-700;
        }

        .btn-close {
          margin-top: -56%;
          margin-right: -3%;
        }
      }

      .sv-features-grid {
        .sv-feature-title {
          @include font(hind, regular);
        }
      }

      .sv-features-modal,
      .sv-features-grid {
        background-color: $gray-50;
        border-radius: 8px;
      }

      .sv-features-grid {
        padding: 24px 72px 8px 72px;
      }

      .sv-features-modal {
        padding: 24px 72px 8px 72px;
      }
    }
  }
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
  position: relative;
}

.img-scroll-container {
  position: relative;

  .overlay-top,
.overlay-bottom {
  position: absolute;
  left: 0;
  right: 0;
  height: 75px;
  z-index: 1;
  pointer-events: none;
}

.overlay-top {
  top: 0;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.80) 80.5%);
  // background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.overlay-bottom {
  bottom: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.80) 100%);
  // background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}
}

.sv-playVideo-modal {
  .btn-close {
    top: -38px !important;
  }
}