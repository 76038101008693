$white-pearl: #fff8f8;
$navy-blue: #0a1632;
$se-primary-red: $primary;
$se-dark-red: $danger;
$se-primary-blue: $secondary;
$se-dark-blue: $info;
$road-grey: $tertiary;
$green: $success;
$dark-grey: #666666;
$black-pearl: #00121d;
$dark-blue: #021432;
$white-grey: #fafafa;
$black-color-opacity: rgba(0, 0, 0, 0.25);
$cherry-red-color: #de1c2f;
$light-red-color: #cccccc;
$dark-grey-color: #3c3c3c;
$dark-blue-color: #031232;
$bright-grey-color: #B0B0B1;
$border-color: $input-border-color;
$dark-navy-blue-color: #00121D;
$black-grey-color: #020202;
$darkest-blue-color: #022157;
$hover-link-color: #23527c;
$black: #1E1E1E;