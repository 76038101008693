.sv-locations-carousel {
  .carousel-indicators {
    margin-bottom: 0;
    [data-bs-target] {
      background-color: $white;
      border-radius: 50%;
    }
  }

  .carousel-control-prev {
    opacity: 1;
  }
  .carousel-control-next {
    opacity: 1;
  }
}

.desktop-carousel {
  background-color: #3c3c3c;
  .slider-container {
    height: 336px;

    .slider-frame {
      height: 100% !important;
    }
  }
}

.desktop-carousel,
.gallery {
  position: relative;
  .slider-container {
    position: static !important;
    height: 100%;
    div {
      &:nth-child(2) {
        z-index: 2 !important;
      }

      &:nth-child(3) {
        z-index: 2 !important;
      }
    }
    .slider-control-centerleft,
    .slider-control-centerright {
      z-index: 2;
      .carousel-control-prev,
      .carousel-control-next {
        pointer-events: none;
        margin: auto 0;
      }
      .MuiButtonBase-root {
        background: rgba(180, 180, 180, 0.8);
        width: 52px;
        height: 28px;
        border-radius: 0;
        position: absolute;
        bottom: 0;
        border: 0;

        .MuiSvgIcon-root {
          font-size: 36px;
          color: $white;
        }
      }
    }

    .slider-control-centerleft {
      .MuiButtonBase-root {
        right: 53px;
      }
    }

    .slider-control-centerright {
      .MuiButtonBase-root {
        right: 0;
      }
    }

    .slider-control-bottomcenter {
      position: absolute;
      left: 0;
      width: 100%;
      height: 28px;
      padding: 6px 24px;
      background-color: #3c3c3c;

      ul {
        top: 2px !important;

        .paging-item {
          height: 12px;
          width: 12px;
          border-radius: 50%;
          border: 3px solid $white;
          margin: 0 3px;

          &.active {
            background-color: $white;
          }
          button {
            opacity: 0 !important;
            transform: translate(0px, -10px);
          }
        }
      }
    }
  }

  .slider-container {
    .slider-frame {
      .slider-list {
        height: 100%;
      }
    }
  }
}

.slider-layout {
  .Scontent {
    display: flex;
    align-items: center;

    .text-box {
      width: 15%;
    }

    .slide-area {
      width: 85%;

      .slider-container {
        .slider-control-centerleft,
        .slider-control-centerright {
          .left-arrow,
          .right-arrow {
            background-color: $white;
            box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.25);
            border: none;
            border-radius: 50%;
            width: 28px;
            height: 28px;
            padding: 2px 6px;

            &:disabled {
              display: none;
            }
          }
        }

        .slide {
          flex: none !important;
          margin-right: 10px;
        }

        .slider-list {
          text-align: center !important;
          .sv-city-landing-link {
            white-space: pre;
          }
        }
      }
    }
  }
}

.video-wrapper {
  // position: relative;
  // padding-bottom: 56.25%;
  z-index: 0 !important;
}

.sv-view-units-mobile {
  .gallery {
    .slider-container {
      height: 281px;

      .slider-frame {
        height: 100% !important;
      }
    }
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-size: 60%;
    background-position: center;
    border-radius: 18px;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(4px);
  }
}

.carousel-container {
  height: 100%;

  .paging-item {
    padding: 1px 6px;
  }
}

.sv-promo-carousel {
  margin-top: 24px;

  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }

  .carousel-indicators {
    margin-bottom: 0;

    button {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $gray-300;
      opacity: 0.4;

      &.active {
        opacity: 1;
      }
    }
  }

  .carousel-item {
    border-radius: 8px;
    padding: 24px 75px;
    transition:
      transform 0.6s ease-in-out,
      opacity 0.6s ease-in-out;
    opacity: 0;
    transform: translateX(100%);
    min-height: 150px;

    .sv-pill-arrow {
      margin-left: 4px;
    }
    
    &.active {
      opacity: 1;
      transform: translateX(0);
    }

    &.prev {
      transform: translateX(-100%);
    }

    &.banner-blue {
      background-color: $secondary-25;

      .sv-pill-link {
        border: 1px solid $secondary-200;
        background: $secondary-50;
        color: $blue-700;

        .sv-pill-arrow {
          filter: brightness(0) saturate(100%) invert(16%) sepia(10%) saturate(7470%) hue-rotate(194deg) brightness(99%) contrast(90%);
        }
      }

      .sv-offer-deadline {
        color: $blue-600;
      }
    }

    &.banner-red {
      background-color: $red-25;

      .sv-pill-link {
        border: 1px solid $red-200;
        background: $red-50;
        color: $red-700;

        .sv-pill-arrow {
          filter: brightness(0) saturate(100%) invert(10%) sepia(68%) saturate(5527%) hue-rotate(340deg) brightness(122%) contrast(112%);
        }
      }

      .sv-offer-deadline {
        color: $red-600;
      }
    }

    .carousel-item-img {
      width: 100px;
      max-width: 100%;
      height: 100px;
      border-radius: 8px;
      margin-left: auto;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }
  }

  .carousel-item-align {
    text-align: center;
  }
}
