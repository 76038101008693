.sv-filter-dropdown {
  button {
    @include font(hind, regular);
    padding: 0;
    border: none;
    font-size: 15px;
    line-height: 26px;
    color: $road-grey;
    margin-left: 7px;

    &:focus {
      box-shadow: none;
    }
  }

  .dropdown-menu {
    &.show {
      background-color: $white;
      padding: 9px;
      border: 1px solid rgba(102, 102, 102, 0.3);
      box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.5);
      border-radius: 11.5px;

      li {
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }

        .sv-multiselect-checkbox {
          text-decoration: none;
          @include font(hind, regular);
          font-size: 16px;
          line-height: 16px;
          color: $road-grey;
          display: flex;
          align-items: center;

          input {
            margin-right: 10px;
            height: 20px;
            width: 20px;
            border: 1px solid rgba(102, 102, 102, 0.3);
            border-radius: 4px;
          }
        }
      }
    }
  }

  .sort-by {
    &.dropdown-menu {
      padding-left: 0;
      padding-right: 0;
    }

    .sort-option {
      cursor: pointer;
      .sv-multiselect-checkbox {
        .form-check-label {
          font-size: 16px;
          cursor: pointer;
        }
      }

      &.active {
        background-color: rgba(0, 138, 224, 0.08);
      }
    }
  }
}

.sv-unit-tabs {
  .nav-pills {
    .sv-nav-pills {
      border: 1px solid $road-grey;
      border-radius: 12px;
      padding: 0 5px;
      margin: 0 5px 10px 0;
      height: 26px;

      &:focus,
      &.active {
        border: 1px solid $se-primary-blue;
        background-color: rgba(0, 138, 224, 0.08);

        button {
          color: $se-primary-blue;
        }
      }

      a {
        text-decoration: none;
      }

      .sv-filter-dropdown {
        button {
          margin-left: 0;
          &::after {
            content: none;
          }
        }
      }

      button {
        text-decoration: none;
        border: none;
        background-color: transparent;
      }

      .para {
        font-size: 15px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.sv-gateAccess-select,
.sv-hours-select {
  .hours-dropdown-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .btn-outline-dark.dropdown-toggle {
    position: relative;
    padding: 8px 30px 8px 14px;
    border-radius: 8px;
    border: 1px solid $gray-50;
    background-color: $gray-50;
    box-shadow: none;

    &.show {
      border-radius: 8px;
      border: 1px solid $secondary-500;
      background: $gray-50;
      box-shadow: none;
    }
  }

  .btn-outline-dark.dropdown-toggle::after {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url("../../../public/images/sv-select-dropdown-icon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .dropdown-menu {
    border-radius: 8px;
    border: 1px solid $gray-200;
    background: $white;
    box-shadow:
      0px 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03);

      .sv-current-day {
        background: $gray-50;

        .sv-store-description {
          font-size: 14px;
          line-height: 16px;
        }
      }

      &:focus {
        background: $gray-50;
      }
  }
}
