@import "../abstracts/figmaVariables";
.se-primary-btn {
  @include se-primary-button;
  background-color: $brand-500;

  &.order-online-btn {
    padding: 5px 16px;
  }

  &.btn:hover {
    @include primary-btn-hover-effect;
  }
  &.btn:active {
    @include primary-btn-hover-effect;
    border: none;
  }
}

.se-secondary-btn {
  @include se-primary-button;
  background-color: $se-primary-blue;

  &.btn:hover {
    @include secondary-btn-hover-effect;
  }

  &.btn:active {
    @include secondary-btn-hover-effect;
    border: none;
  }
}

.se-secondary-btn-white {
  height: 100%;
  display: flex;
  padding: 5px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: $secondary-50;
  &.btn:hover {
    background-color: $secondary-100;
  }

  &.btn:active {
    background-color: $secondary-100;
    border: none;
  }
}

.primary-card-btn {
  @include se-card-buttons;
  background-color: $se-primary-red;

  &.btn:hover {
    @include primary-btn-hover-effect;
  }

  &.btn:active {
    @include primary-btn-hover-effect;
    border: none;
  }
}

.secondary-card-btn {
  @include se-card-buttons;
  background-color: $se-primary-blue;

  &.btn:hover {
    @include secondary-btn-hover-effect;
  }

  &.btn:active {
    @include secondary-btn-hover-effect;
    border: none;
  }
}

.navy-bold-btn {
  @include se-card-buttons;
  background-color: $navy-blue;
  text-transform: none;
  font-size: 16px;
  font-weight: bold;
  color: $white;
  line-height: 1.5;
  img {
    margin-right: 6px;
  }

  &.btn:hover {
    @include secondary-btn-hover-effect;
  }

  &.btn:active {
    @include secondary-btn-hover-effect;
    border: none;
  }
}

.sv-primary-red-btn {
  border: none;
  border-radius: 20px;
  background-color: $se-primary-red;
  padding: 7px 12px;
  @include font(hind, bold);
  font-size: 16px;
  line-height: 26px;
  color: $white;
  height: 42px;
  text-decoration: none;

  &.btn:hover {
    @include primary-btn-hover-effect;
  }

  &.btn:active {
    @include primary-btn-hover-effect;
    border: none;
  }
}

.article-link {
  background-color: $se-primary-blue;
  border: none;
  border-radius: 0;
  @include font(hind, bold);
  font-size: 14px;
  line-height: 28px;
  text-transform: uppercase;
  color: $white;
  text-decoration: none;
  padding: 3px 9px;

  &.btn:hover {
    @include secondary-btn-hover-effect;
  }

  &.btn:active {
    @include secondary-btn-hover-effect;
    border: none;
  }
}

.learn-more {
  @include font(hind, regular);
  font-size: 16px;
  line-height: 24px;
  color: $road-grey;
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    color: $road-grey;
    text-decoration: underline;
  }
}

.se-primary-outline-btn {
  @include se-primary-button;
  line-height: 16px;
  color: $se-primary-red;
  border: 1px solid $se-primary-red;
  border-radius: 20px;
  padding: 14px 18px;
  text-transform: uppercase;

  &:hover {
    color: $danger;
    border: 1px solid $danger;
  }
}

.se-primary-outline-btn-no-upper-case {
  text-transform: unset !important;
}

.se-primary-outline-btn-no-upper-case {
  text-transform: unset !important;
}

.borderless-btn {
  @include borderless-btn;
  cursor: pointer;
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.more-less {
  cursor: pointer;
}

.se-primary-header-btn {
  font-size: 16px;
  height: 56px;
}

// Revamping css begins from here 
.sv-back-btn {
  @include font(hind, semi-bold);
  padding: 10px 14px;
  color: $gray-800;
  font-size: 14px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.5);

  &:hover,
  &:focus,
  &:active {
    color: $gray-800;
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.sv-play-video-btn {
  @include font(hind, semi-bold);
  border-radius: 8px;
  border: 1px solid $brand-500;
  background: $brand-500;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 12px 18px;
  font-size: 16px;
  color: $white;

  &:hover {
    border: 1px solid $red-700;
    background: $red-700;
    color: $white;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  &:focus,
  &:hover {
    border: 1px solid $red-600;
    background: $red-600;
    color: $white;
    box-shadow:
      0px 1px 2px 0px rgba(16, 24, 40, 0.05),
      0px 0px 0px 4px rgba(241, 93, 95, 0.3);
  }
}

.sv-plus-images-btn {
  @include font(hind, semi-bold);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(8px);
  font-size: 16px;
  padding: 12px 18px;
  color: $gray-800;

  &:focus,
  &:hover,
  &:active {
    color: $gray-800;
  }
}

.sv-close-btn {
  &.btn-close {
    background-color: $brand-500;
    color: $white;
    border-radius: 8px;
    border: 1px solid $brand-500;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 8px !important;
    display: flex !important;
    align-items: center !important;
    background-image: none !important;
    opacity: 1;
  }
}

.sv-navigation-btn {
  &.btn {
  border-radius: 8px;
  padding: 12px;
  border: 2px solid $blue-700;
  background: $white;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  width: 48px;
  height: 48px;

  &:hover, &:focus {
    background: $white;
    border: 2px solid $blue-700;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  }
}

.sv-revamp-blue-btn {
  &.btn {
    @include font(hind, semi-bold);
    background-color: $blue-700;
    padding: 12px 18px;
    color: $white;
    font-size: 20px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid $blue-700;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    a {
      white-space: nowrap;
      display: flex;
      justify-content: center;
    }

    &:hover, &:focus {
      background-color: $blue-700;
      color: $white;
      border: 1px solid $blue-700;

      img {
        filter: brightness(0) saturate(100%) invert(99%) sepia(1%) saturate(5853%) hue-rotate(246deg) brightness(136%) contrast(100%);
      }
    }
  }
}
