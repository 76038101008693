// Base
$base-white: #FFFFFF;
$base-black: #000000;

$brand-200: #FFA8B9;
$brand-500: #E2002D;
$brand-600: #B8002A;
$brand-700: #96001E;

$secondary-25: #F5FAFF;
$secondary-50: #EFF8FF;
$secondary-100: #D1E9FF;
$secondary-200: #B2DDFF;
$secondary-400: #3F96F3;
$secondary-500: #2E90FA;
$secondary-700: #175CD3;
$secondary-800: #073f78;

$error-300:#FEC84B;

// Red
$red-500: #E2002D;
// Gray
$gray-25: #FCFCFD;
$gray-50: #F9FAFB;
$gray-100: #F2F4F7;
$gray-200: #EAECF0;
$gray-300: #D0D5DD;
$gray-400: #98A2B3;
$gray-500: #667085;
$gray-600: #475467;
$gray-700: #344054;
$gray-800: #182230;
$gray-900: #101828;
$gray-950: #0C111D;


$gray-400: #8895A3;
$blue-700: #094F9A;
$blue-500: #1D3766;
$blue-600: #0b5aae;

$red-25: #FFF4F6;
$red-50: #FFEDF0;
$red-200: #FFA8B9;
$red-500: #E2002D;
$red-600: #C90028; 
$red-700: #9e001f;

// sizes
$large-screen-width: 1280px;
$tablet-screen-width: 970px;
$mobile-screen-width: 576px;
$units-tablet-screen-width: 1200px;
$units-mobile-screen-width: 743px;

