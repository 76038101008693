@import "../abstracts/figmaVariables";

.container-no-margin {
  margin: 0 !important;
  padding: 0px !important;
}

.sv-breadcrumb {
  height: 28px;
  display: flex;
  align-items: center;
  margin: 12px 0;

  .breadcrumb {
    align-items: center;
    margin: unset;

    .breadcrumb-item {
      padding-left: 5px;
      text-transform: capitalize;

      &::before {
        padding-right: 5px;
      }

      a {
        @include font(hind, regular);
        font-size: 14px;
        line-height: 20px;
        color: $gray-400;
        text-decoration: unset;
      }

      &.active {
        font-size: 14px;
        color: $road-grey;
      }
    }
  }
}

.sv-breadcrumb-revamp {
  height: 28px;
  display: flex;
  align-items: center;
  margin: 8px 0;

  .breadcrumb {
    align-items: center;
    margin: unset;

    .breadcrumb-item {
      text-transform: capitalize;
      position: relative;

      &::before {
        content: "" !important;
      }

      a {
        @include font(hind, regular);
        font-size: 14px;
        line-height: 20px;
        color: $gray-800;
        text-decoration: none;
        cursor: pointer;
      }

      &.active {
        font-size: 14px;
        color: $gray-400;
        line-height: 21px;
      }
    }

    .breadcrumb-separator {
      display: inline-block;
      width: 10px; /* Adjust as needed */
      height: 10px; /* Adjust as needed */
      background-image: url("../../../public/images/breadcrumb-right-arrow.svg");
      background-repeat: no-repeat;
      background-size: inherit;
      background-position: center;
      margin: 0 12px; /* Space between items */
    }
  }
}

.previousLink {
  flex-basis: auto;
  text-align: left;
  margin-left: 20px;

  a {
    @include font(hind, regular);
    font-size: 14px;
    line-height: 22px;
    color: $se-primary-blue;
    text-decoration: underline;

    &:hover {
      color: $info;
    }
  }
}
