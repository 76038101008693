@import "../abstracts/figmaVariables";

h1 {
  @include font(hind, bold);
  font-size: 60px;
  line-height: 60px;
  text-transform: uppercase;
  color: $white;
  text-shadow: 0px 3px 6px $black-color-opacity;
}

h4 {
  @include font(hind, medium);
  font-size: 40px;
  line-height: 44px;
  color: $white;
  text-transform: capitalize;
  margin-bottom: 30px;
}

.main-heading {
  @include font(hind, bold);
  font-size: 34px;
  line-height: 54px;
  color: $road-grey;
  text-shadow: none;
  text-transform: none;
}

h2 {
  @include font(hind, medium);
  font-size: 32px;
  line-height: 38px;
  text-transform: capitalize;
  color: $road-grey;
}

.section-heading {
  @include font(hind, medium);
  font-size: 32px;
  line-height: 60px;
  color: $road-grey;
  text-transform: capitalize;
  text-align: center;
  position: relative;
  margin-bottom: 35px;
  text-shadow: none;
  text-transform: none;

  &::after {
    content: "";
    position: absolute;
    background-color: $se-primary-red;
    max-width: 94px;
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.section-heading-dark {
  @include font(hind, normal);
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  color: $black;
  text-align: center;
  position: relative;
  margin-bottom: 35px;
  text-shadow: none;
  text-transform: none;
  letter-spacing: -0.023em;

  &::after {
    content: "";
    position: absolute;
    background-color: $se-primary-red;
    max-width: 218px;
    height: 2px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

@media (max-width: 768px) {
  .section-heading-dark {
    @include font(hind, normal);
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;

    &::after {
      content: "";
      position: absolute;
      background-color: $se-primary-red;
      max-width: 150px;
      height: 2px;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}

.sv-card-titles {
  @include font(hind, medium);
  font-size: 32px;
  line-height: 32px;
  color: $road-grey;
}

.sv-dark-grey-bold-text {
  @include font(hind, bold);
  font-size: 32px;
  line-height: 33px;
  color: $dark-grey-color;
}

.sv-white-para {
  @include font(hind, medium);
  font-size: 24px;
  line-height: 38px;
  color: $white;
}

.sv-white-bold-text {
  @include font(hind, bold);
  font-size: 26px;
  line-height: 42px;
  color: $white;
  margin-bottom: 0;
}

.sv-blue-bold-text {
  @include font(hind, bold);
  font-size: 24px;
  line-height: 38px;
  color: $se-primary-blue;
}

.sub-heading {
  @include font(hind, bold);
  font-size: 24px;
  line-height: 30px;
  color: $road-grey;
}

.sv-grey-medium-text {
  @include font(hind, medium);
  font-size: 22px;
  line-height: 35px;
  color: $road-grey;
}

.sv-grey-bold-small-text {
  @include font(hind, bold);
  font-size: 16px;
  line-height: 26px;
  color: $road-grey;
}
.sv-dark-grey-medium-regular-text {
  @include font(hind, regular);
  font-size: 30px;
  line-height: 1.5;
  color: $gray-400;
}
.sv-dark-grey-small-regular-text {
  @include font(hind, regular);
  font-size: 14px;
  line-height: 20px;
  color: $gray-400;
}

h3 {
  @include font(hind, bold);
  font-size: 20px;
  line-height: 30px;
  text-transform: capitalize;
  color: $road-grey;
}

.context-para {
  @include font(hind, medium);
  font-size: 18px;
  line-height: 28.82px;
  color: $road-grey;
}

.sv-red-small-text {
  @include font(hind, semi-bold);
  font-size: 14px;
  color: $base-white;
  line-height: unset;
  text-transform: lowercase;
}

.sv-black-large-text {
  @include font(hind, bold);
  font-size: 27px;
  line-height: 27px;
  color: $black;
}

.sv-grey-regular-text {
  @include font(hind, regular);
  font-size: 17px;
  line-height: 28px;
  color: $road-grey;
}

.sv-promo-text-mobile {
  margin-left: -120px;
}

.sv-promo-text {
  @include font(hind, semi-bold);
  font-size: 18px;
  line-height: 1;
  color: $road-grey;
  display: grid;
  .sv-promo-icon {
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentcolor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.25rem;
  }
  // hotfix
  > span > svg {
    width: 1em;
    max-width: 1em;
    fill: currentcolor;
  }
}

.para {
  @include font(hind, regular);
  font-size: 16px;
  line-height: 24px;
  color: $road-grey;
}

.link {
  @include font(hind, regular);
  font-size: 16px;
  line-height: 24px;
  color: $se-primary-blue;
  text-decoration: none;
  &:hover {
    color: $se-primary-blue;
    text-decoration: underline;
  }
}

.sv-road-grey-para {
  @include font(hind, medium);
  font-size: 16px;
  line-height: 26px;
  color: $road-grey;
}

.ratings {
  @include font(hind, regular);
  font-size: 14px;
  line-height: 22px;
  color: $road-grey;
}

.sv-grey-small-text {
  @include font(hind, medium);
  font-size: 14px;
  line-height: 22px;
  color: $road-grey;
  opacity: 0.6;
}

.click-for-details {
  @include font(hind, bold);
  font-size: 16px;
  line-height: 26px;
  color: $se-primary-red;
  text-decoration: none;

  &:hover {
    color: $se-dark-red;
  }
}

.sv-dark-grey-regular-text {
  @include font(hind, regular);
  font-size: 20px;
  line-height: 32px;
  color: $dark-grey-color;
}

.sv-dark-grey-bold-para {
  @include font(hind, bold);
  font-size: 18px;
  line-height: 20px;
  color: $dark-grey-color;
}

.sv-secondary-link {
  @include font(hind, regular);
  font-size: 16px;
  line-height: 18px;
  color: $secondary;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.sv-results-near-you-text {
  @include font(hind, bold);
  font-size: 16px;
  line-height: 20px;
  color: $road-grey;
  padding: 10px 0;
}

.sv-storeName {
  @include font(hind, bold);
  font-size: 36px;
  line-height: 40px;
  color: $black;
}

@media (max-width: 576px) {
  .sv-storeName {
    font-size: 30px;
    line-height: 36px;
  }
}

.sv-storeID {
  @include font(hind, regular);
  font-size: 14px;
  line-height: 18px;
  color: $gray-400;
}

.sv-storelandmark {
  @include font(hind, regular);
  font-size: 20px;
  line-height: 24px;
  color: $gray-900;
}

.sv-hours-blue-text {
  @include font(hind, medium);
  font-size: 16px;
  line-height: 20px;
  color: $blue-600;
}

.sv-storeAddress {
  @include font(hind, semi-bold);
  font-size: 16px;
  line-height: 20px;
  color: $blue-700;
}

@media (max-width: 576px) {
  .sv-storeAddress {
    font-size: 14px;
    line-height: 20px;
  }
}

.sv-feature-name {
  @include font(hind, regular);
  font-size: 16px;
  line-height: 20px;
  color: $gray-900;
}

.sv-store-description {
  @include font(hind, regular);
  font-size: 18px;
  line-height: 20px;
  color: $gray-900;

  p {
    margin-bottom: 0;
  }

  @media (max-width: 576px) {
    font-size: 14px;
    line-height: 20px;
  }
}

.sv-hour-day {
  @include font(hind, semi-bold);
  font-size: 14px;
  line-height: 16px;
  color: $gray-900;
}

.sv-hour-time {
  @include font(hind, medium);
  font-size: 16px;
  line-height: 18px;
  color: $gray-600;
}

.sv-gray-large-heading {
  @include font(hind, bold);
  font-size: 30px;
  line-height: 30px;
  color: $gray-900;
}

.sv-dark-black-bold-para {
  @include font(hind, bold);
  font-size: 18px;
  line-height: 20px;
  color: $gray-900;
}

.sv-offer-deadline {
  @include font(hind, regular);
  font-size: 16px;
  line-height: 18px;
}

.sv-hours-open-text {
  @include font(hind, regular);
  font-size: 16px;
  line-height: 20px;
  color: $gray-600;
}