@import "../abstracts/figmaVariables";

.unit-details-table {
  border-top: 1px solid $gray-100;
  border-bottom: 1px solid $gray-100;

  table {
    table-layout: fixed;
    margin-bottom: 0px;
    tr {
      border-top: 1px solid $gray-100;

      td {
        padding: 20px 8px 10px 0;
        &:nth-child(4) {
          width: 85px;
        }
        &:last-child {
          padding-right: 0;
        }
      }

      &:first-child {
        border-top: none;
      }
    }
  }

  .unit-table-thumbnail {
    width: 117px;
    height: 79px;

    .find-unit-size {
      margin: 0;

      .unit-sizes {
        .details {
          margin-top: 0;

          .video-card {
            filter: none;
            border-radius: 8px;
            .thumbnail {
              width: 100%;
              height: 80px;
              object-fit: cover;
              border-radius: 8px;
            }

            .play-btn {
              position: absolute;
              top: 50%;
              left: 50%;
              cursor: pointer;
              transform: translate(-50%, -50%);
              transition: 0.5s linear opacity;

              &:hover {
                height: 35px;
                width: 35px;
              }
            }
          }
        }
      }
    }
  }

  .sv-unit-dimensions {
    width: 150px;

    .sv-dark-grey-bold-text {
      white-space: nowrap;
      margin-bottom: 0;
      color: $gray-900;
      font-size: 32px;
      @include font(hind, bold);
    }
  }

  .sv-grey-bold-small-text {
    font-size: 18px;
    line-height: 20px;
    color: $dark-grey-color;
    margin-bottom: 5px;
  }

  .sv-grey-small-regular-text {
    @include font(hind, semi-bold);
    color: $gray-600;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 20px;
  }

  .unit-table-btns {
    width: 200px;
    .se-primary-btn,
    .se-secondary-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      padding: 10px 8px 8px 8px;
      width: 100%;
      margin-bottom: 10px;
      font-size: 20px;
      line-height: 36px;
      @include font(hind, bold);
    }
  }

  .sv-red-small-text {
    @include font(hind, semi-bold);
    font-size: 14px;
    color: $base-white;
    line-height: unset;
    text-transform: lowercase;
    white-space: nowrap;
  }
}

.sv-hours-table {
  .table {
    thead {
      text-align: center;

      tr {
        th {
          @include font(hind, bold);
          font-size: 16px;
          line-height: 20px;
          color: $dark-grey-color;
          background-color: transparent;
          padding: 2px;
        }
      }
    }

    tbody {
      text-align: center;

      tr {
        td {
          @include font(hind, regular);
          font-size: 16px;
          line-height: 20px;
          color: $dark-grey-color;
          background-color: transparent;
          padding: 2px;

          &:first-child {
            text-align: left;
          }
        }

        &.active {
          td {
            @include font(hind, bold);
          }
        }
      }
    }
  }
}

.store-hours-table {
  height: 100%;
  margin: 0;
  text-align: left;

  th {
    padding: 2px;
    background-color: transparent;
    color: $white;
  }

  td {
    padding: 0 2px;
    white-space: nowrap;
    background-color: transparent;
    color: $white;
  }
}

.store-time-tooltip {
  .tooltip-inner {
    padding: 15px 10px;
  }
}

.sv-hours-revamp-table {
  margin: 0 auto;

  thead {
    th {
      @include font(hind, bold);
      font-size: 14px;
      line-height: 20px;
      color: $gray-800;
      background-color: transparent;
      border: none;
      padding: 4px 8px;
    }
  }

  tbody {
    td {
      @include font(hind, regular);
      color: $gray-800;
      font-size: 14px;
      line-height: 20px;
      background-color: transparent;
      border: none;
      padding: 4px 8px;
    }
    }
}
