.sv-tabs {
  background-color: $navy-blue;
  padding: 13px 0;

  .nav-pills {
    .nav-item {
      margin-right: 30px;
    }
  }

  .nav-link {
    @include font(hind, regular);
    color: $white;
    font-size: 16px;
    line-height: 19px;
    border: 1px solid #ffffff;
    border-radius: 11.5px;
    min-width: 115px;
    padding: 2px 30px;
    height: 23px;
    margin: auto;

    &.active {
      background: white;
      color: #0a1632;
    }
  }

  button.nav-link {
    background-color: transparent;
  }
}

.sv-tab-content {
  margin-top: 60px;
  transition: 0.3s;
  animation: fadeIn 3s;
}

.mobile-sv-tabs {
  padding: 13px 8px;

  .slider-container {
    .slider-control-centerleft,
    .slider-control-centerright {
      .left-arrow,
      .right-arrow {
        background-color: $navy-blue;
        border: none;
        color: $white;

        &:disabled {
          display: none;
        }
      }
    }

    .slider-list {
      .slide {
        .nav-link {
          padding: 2px 22px;

          &.active {
            background: $white;
            color: $navy-blue;
          }
        }

        .hReno {
          .nav-link {
            margin-left: -1px;
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }
    }
  }
}

.sv-pill-link {
  @include font(hind, semi-bold);
  padding: 3px 8px 1px 8px;
  border-radius: 16px;
  font-size: 14px;
  line-height: 14px;
  text-decoration: none;
  height: max-content;
  width: max-content;
  display: flex;
  align-items: center;
}

.sv-store-features-tabs {
  margin-bottom: 12px;

  .nav-tabs {
    border-bottom:  1px solid $gray-500;

    .nav-item {
      .nav-link {
        @include font(hind, regular);
        color: $gray-600;
        font-size: 14px;
        line-height: 18px;
        border: none;
        box-shadow: none;
  
        .tab-img {
          margin-right: 8px;
        }
  
        &.active {
          @include font(hind, semi-bold);
          color: $secondary-800;
          font-size: 14px;
          line-height: 18px;
          border-radius: 8px 8px 0px 0px;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          background-color: $secondary-50;
          border-bottom: 3px solid $secondary-400;
          border-top: none;
          border-right: none;
          border-left: none;
  
          .tab-img {
            margin-right: 8px;
  
            img {
              filter: brightness(0) saturate(100%) invert(14%) sepia(85%) saturate(2013%) hue-rotate(195deg)
                brightness(98%) contrast(95%);
            }
          }
        }
      }
    }
  }

  .tab-content {
    #features-tab-pane {
      padding: 24px 16px;
    }

    #hours-revamp-tab-pane {
      padding: 12px;
    }

    .tab-pane {
      background-color: $gray-50;
      border-radius: 0px 0px 8px 8px;
    }
  }
}

.sv-deal-pill {
  @include font(hind, regular);
  padding: 1px 8px 1px 8px;
  border-radius: 16px;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  height: max-content;

  &#dealPill {
    border: 1px solid $brand-200;
    background: $white;
    color: $red-700;
    width: max-content;
  }
}

#features-tab-pane {
  .unitFeaturesContent {
    padding: 0;
  }
}

#hours-revamp-tab-pane {
  padding: 12px 16px;
}