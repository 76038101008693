@mixin smooth-hover-effect {
  transition: all 0.7s ease;
}

@mixin primary-scroll {
  &::-webkit-scrollbar-track {
    background-color: transparent;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.15);
  }

  &::-webkit-scrollbar {
    height: 20px;
    width: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #b3b3b3;
    opacity: 0.2;
    border-radius: 5px;
  }
}

@mixin image-scroll {
  &::-webkit-scrollbar-track {
    background-color: transparent;
    // box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.15);
  }

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #b3b3b3;
    opacity: 0.2;
    border-radius: 8px;
  }
}

@mixin background-image-prop {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin se-primary-button {
  @include font(hind, bold);
  color: $white;
  font-size: 19px;
  line-height: 33px;
  height: 44px;
  text-decoration: none;
  text-align: center;
  border: none;
  border-radius: 10px;
  text-transform: uppercase;
  padding: 6px 25px;
}

@mixin se-card-buttons {
  @include font(hind, bold);
  font-size: 11px;
  line-height: 17px;
  color: $white;
  text-transform: uppercase;
  text-decoration: none;
  padding: 4px 14px;
  border-radius: 20px;
  border: none;
}

@mixin primary-btn-hover-effect {
  background-color: $se-dark-red;
  color: $white;
}

@mixin secondary-btn-hover-effect {
  background-color: $se-dark-blue;
  color: $white;
}

@mixin bright-grey-small-text {
  @include font(hind, regular);
  font-size: 16px;
  line-height: 26px;
  color: $road-grey;
}

@mixin borderless-btn {
  border: none;
  padding: 0;
}

@mixin section-heading {
  @include font(hind, medium);
  font-size: 32px;
  line-height: 60px;
  color: $road-grey;
  text-transform: capitalize;
  text-align: center;
  position: relative;
  margin-bottom: 53px;

  &::after {
    content: "";
    position: absolute;
    background-color: $se-primary-red;
    max-width: 94px;
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}
