* {
  outline: 0;
}

html {
  box-sizing: border-box;
  height: 100%;
  min-height: 100%;
  scroll-behavior: smooth;
}

html.sv-imagesModal-scroll {
  /* Ensure scrollbar appears */
  overflow-y: scroll !important;
  
  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

/* For WebKit Browsers (Chrome, Edge, Safari) */
html.sv-imagesModal-scroll::-webkit-scrollbar {
  width: 8px;
  background: #f1f1f1; /* Ensure the track background is visible */
}

/* Make the scrollbar track visible */
html.sv-imagesModal-scroll::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light background */
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

/* Scrollbar Thumb (the draggable part) */
html.sv-imagesModal-scroll::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* On Hover */
html.sv-imagesModal-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}


body {
  @include font(hind, regular);
  font-size: 16px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  background-color: $white;
}

.sv-wrapper {
  min-height: calc(100vh - 330px);
  margin-top: 100px;
}
